body {
  color: white;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
#root {
  height: 100%;
  font-family: "Ubuntu";
}

.App {
  height: 100%;
}
html {
  height: 100%;
  min-height: 100%;
}
:root {
  --header-size: 5vh;
  --bg-color: #101010;
}
/*------- header --------*/
header {
  padding: 20px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}
.logo {
  height: fit-content;
  width: var(--header-size);
  position: absolute;
  z-index: 1000;
  animation: rotation 20s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-container {
  display: flex;
  justify-content: right;
  align-items: center;
  position: absolute;
  background-color: var(--bg-color);
  width: 100%;
  z-index: 999;
  height: var(--header-size);
  font-weight: bold;
}
.navbar-hamburger {
  display: none;
}
.hamburger-button {
  border: none;
  background-color: transparent;
  & svg {
  fill: white;
  height: 50px;
  width: 50px;
  }
}

.navbar-links {
  display: flex;
}
.navbar-links a {
  text-decoration: none;
  color: white;
  font-size: 15px;
  margin: 25px;
  &:hover {
    color: grey;
  }
  &.active {
    text-decoration: underline;
    text-underline-offset: 10px;
  }
}
.navbar-links-offcanvas a {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: white;
  font-size: 15px;
  margin: 25px;
  &:hover {
    color: grey;
  }
    &.active {
    text-decoration: underline;
    text-underline-offset: 10px;
  }
}
.offcanvas {
  background-color: black;
  display: flex;
  position: absolute;
  width: 33% !important;
  transition: all 1 linear;
  opacity: 1;
}

.wrapperdiv {
  height: var(--header-size);
}

.sidebar {
  position: absolute;
  display: flex;
  background-color: var(--bg-color);
  z-index: 999;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  width: var(--header-size);
}
.sidebarlinks {
  display: flex;
  flex-direction: column;
}
.sidebarlinks svg {
  width: calc(var(--header-size) / 2.5);
  height: calc(var(--header-size) / 2.5);
}
.sidebarlinks a {
  height: calc(var(--header-size) / 2.5);
  margin-bottom: 25px;
}
.sidebarlinks a:hover {
  filter: invert(48%);
}
/*---------HOME------------ */
.home {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;

}
.home > .text {
  margin: auto;
  font-size: 2vw;
}
.picture img {
  max-height: 1250px;
}

/*--------VISIBLE PAGE CONTENT---------- */
.content {
  display: flex;
  background-color: var(--bg-color);
  margin-left: var(--header-size);
  height: 100%;
  box-sizing: border-box;
  padding-top: var(--header-size);
}
.transition {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x:hidden;

}

@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
  }
  .navbar-hamburger {
    display: flex;
  }
}
