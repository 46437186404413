.container-fluid {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gameArea div {
  content: "";
  padding-top: 100%; /* This sets the aspect ratio (1:1) for height based on width */
}
.gameArea {
  display: grid;
  border-style: solid;
  grid-template-columns: repeat(20, 1fr); /* 20 columns with equal width */
  grid-template-rows: repeat(20, 1fr); /* 20 rows with equal height */
  gap: 1px; /* Adjust the gap between cells as needed */
  width: 100%;
  max-width: 800px;
}
.snake {
  background-color: greenyellow;
}
.apple {
  background-color: red;
}
.touchControlArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  width: 90%;
  height: 90%;
  z-index: 99999;
  box-sizing: border-box;
  margin-right: 5px;
}
.top{
  height: 25%;
  width: 100%;
}
.left{
  z-index: 99;
  width: 50%;
  height: 50%;
}
.right{
  width: 50%;
  height: 50%;
}
.bottom{
  height: 25%;
  width: 100%;
}
p{color: white;}
